/* sidebar.search.blade.php */
var bool = false;
var timeout;
setTimeout(function search(){
   $("#term").keyup(function (){
      $(window).keydown(function(event){
         if(event.keyCode == 13) {
            event.preventDefault();
            return false;
         }
      });
      // hide it first
      //$(".loader").hide();
      $("body *").mouseup(function (e){
         var container = $(".res");
         if (!container.is(e.target)){
              //container.addClass("pullUp");
              container.slideUp( 400 ).delay( 600 );
          }
      });
      
      var token = $("meta[name='csrf-token']").val();
      var term = $("#term").val();
      var home = $("#url").val();
      var path = home + "/search/" +term;
      if(term.length > 2){
         $.ajax({
            url: path,
            data: token,
            async: true,
            beforeSend: function() {$('#loader').show();$('#glass').hide();},
            complete: function(){$("#loader").hide();$('#glass').show();},
            success: function(data){
               $(".results").html(data);
            }
         }).responseText;
      }else{
         $(".results").html('');
      }
   });
},500);

$(".hover-search").mouseover(function(){
   clearTimeout(timeout);
   $(".results").show();
}).mouseout(function(){
   if(!bool && !$(".hover-search").is(":focus")){
         timeout = setTimeout(function(){
            $(".results").hide();
         }, 500);
      }
}).focusout(function(){
   timeout = setTimeout(function(){
            $(".results").hide();
         }, 500);
});
/* end sidebar.search.blade.php */

/* hotel.blade.php */
Vue.config.debug = true;
    var vm = new Vue({
         el: '#hotel-search',
         data: {
            searchData:{
               city: '',
            },
                rooms: 1,
         },
         ready: function(){
            //console.log(this.$els.checkin);
                //console.log(this.$els.roomswrapper);
            //$(this.$els.checkin).datepicker();
         },
         computed: {
            errors: function(){
               for(var key in this.searchData){
                  if(! this.searchData[key]) return true;
               }
               return false;
            },
                moreRooms: function(){
                    if(this.rooms > 1){
                        var row = '<div><input type="number"></div>';
                        var $element = $(this.$els.roomswrapper).append(row);
                        this.$compile($element.get(0));
                        return true;
                    }
                    return false;
                }
         },
         methods: {
            submitForm: function(){
               if(! this.errors) this.$els.findhotel.submit();
            }
         }
        });

$( document ).ready(function(){
  $("#hotel-city-container").width($(".hotel-text-container").width()+20);
});

$(window).resize(function() {
  $("#hotel-city-container").width(($(".hotel-text-container").width()+20));
});

$('#hotel-city').on('focusin keyup',function(){
  $("#hotel-city-container").show();
  var token = $("meta[name='csrf-token']").val();
  var term = $("#hotel-city").val();
  var home = $("#url").val();
  var path = home + "/hotels/suggestions/"+term;
  $.ajax({
    url: path,
    data: token,
    async: true,
    success: function(data){
      if(data.length != ""){
        $("#hotel-city-container").html(data);
        $('.hotel-suggestion-row').click(function(){
          $('#hotel-city').val($(this).find('.row-city').text());
          $("#hotel-city-container").hide();
          $('.button.warning.radius.right.hotel-btn').prop('disabled', false);
        });
      }
    }
  }).responseText;
}).focusout(function(){
  setTimeout(function(){
    
    var focus=$(document.activeElement);
    if (focus.is("#hotel-city") || $('#hotel-city').has(focus).length) {
      console.log("still focused");
    } else {
      $("#hotel-city-container").hide();
    }
  },250);
});
/* end hotel.blade.php */

/* sidebar.places.blade.php */
$(document).ready(function(){
   if(window.location.pathname == '/' || window.location.pathname == '/home'){
      $.ajax({
         url: "/locations/places",
         async: false,
         success: function(data){
            $('.sidebar-places').html(data);
         }
      }).responseText;
   }
});
/* end sidebar.places.blade.php */


/* sidebar.event.blade.php */
var events = [];
   $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
$(document).ready(function(){
      var url = $("input[name=url]").val();
      var token = $("meta[name='csrf-token']").val();
      $.ajax({
         url: url+"/events/calendar",
         data: token,
         async: false,
         success: function(data){
            $.each(data, function( evnt, attrib){
               var yr = new Date();
               var year = yr.getFullYear();
               var date = attrib['month']+'/'+attrib['day']+'/'+'2001';
               if(attrib['day']==0){
                  events.push({LocationId: attrib['location_id'], Id: attrib['id'], Name: attrib['name'], Slug: attrib['slug'], Date: new Date(date), Month: attrib['month']});
               }else{
                  events.push({LocationId: attrib['location_id'], Id: attrib['id'], Name: attrib['name'], Slug: attrib['slug'], Date: new Date(date)});
               }
            });
            calendarInit();
         }
      }).responseText;
}); //end document ready

function calendarInit() {
      console.log('init calendar!');
      var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
      $( "#datepicker_event" ).datepicker({
         dateFormat: "dd MM",

         beforeShowDay: function(date) {
            var year = '2001';
            var month = date.getMonth()+1;
            var day = date.getDate();
            var new_date = month+'/'+day+'/'+year;
            var date = new Date(new_date);
             var result = [true, '', null];
             var matching = $.grep(events, function(event) {
               if(event.Month){
                  return false;
               }else{
                  return event.Date.valueOf() === date.valueOf();
               }
             });

             if (matching.length) {
                 result = [true, 'highlight', null];
             }
             return result;
         },

         onChangeMonthYear: function(year, month) {
            var i = 0,
               result = '',
               events_month = [];

            while (i < events.length) {
                 if(month == events[i].Month){
                  events_month.push(events[i]);
                 }
                 i++;
             }
             result = '<h5>'+months[month-1]+' Events</h5><hr class="custom-hr-dashed-white">';
            $.each(events_month, function(evnt, ev){
               result = result + "<i class='fi-link size-14'></i>&nbsp;&nbsp;<a href="+ url +"/events/" + ev['Slug'] + ">" + ev['Name'] + "</a><br />"
              });
              $('#events-monthly-results').html('<br />' + result);
              $('#events-day-results').html('');
         },

         onSelect: function(dateText) {
            var url = $("input[name=url]").val();
            var events_day = [];
             var date,
                 selectedDate = new Date(dateText),
                 i = 0,
                 event = null,
                 result = '';
             var month = selectedDate.getMonth();
             var day = selectedDate.getDate();
             var strtotime = '2000-'+ month + '-' + day;
             while (i < events.length && !event) {
                 // date = events[i].Date;
                 if (selectedDate.getMonth() === events[i].Date.getMonth()) {
                  if (selectedDate.getDate() === events[i].Date.getDate()) {
                        events_day.push(events[i]);
                  }
                 }
                 i++;
             }
              if (events_day.length) {
               result = result + '<h5>'+months[month] + ' ' + day + ' Events</h5><hr class="custom-hr-dashed-white">';
               console.debug(strtotime);
                 $.each(events_day, function(evnt, ev){
                  result = result + "<i class='fi-link size-14'></i>&nbsp;&nbsp;<a href="+ url +"/events/" + ev['Slug'] + ">" + ev['Name'] + "</a><br />"
                 });
             $('#events-day-results').html(result);
              };

         }

       });

      var url = $("input[name=url]").val();
      var todaysDate = new Date();
      var i = 0;
      var events_day = [];
      var events_month = [];
      var today = todaysDate.getDate();
      event = null,
      result = '';
       while (i < events.length && !event) {
           today = events[i].Date;
           if (todaysDate.getMonth() === today.getMonth() || events[i].Month) {
            if (todaysDate.getDate() == today.getDate()) {
                  events_day.push(events[i]);
            }else if(todaysDate.getMonth()+1 == events[i].Month){
               events_month.push(events[i]);
            }
           }
           i++;
       }
      if(today){
         if(events_month.length) {
               result = '<h5>'+months[todaysDate.getMonth()]+' Events</h5><hr class="custom-hr-dashed-white">';
               $.each(events_month, function(evnt, ev){
                  result = result + "<i class='fi-link size-14'></i>&nbsp;&nbsp;<a href="+ url +"/events/" + ev['Slug'] + ">" + ev['Name'] + "</a><br />"
                 });
                 $('#events-monthly-results').html('<br />' + result);
         }
         if (events_day.length) {
               result = '<h5>Today\'s Events</h5><hr class="custom-hr-dashed-white">';
                 $.each(events_day, function(evnt, ev){
                  result = result + "<i class='fi-link size-14'></i>&nbsp;&nbsp;<a href="+ url +"/events/" + ev['Slug'] + ">" + ev['Name'] + "</a><br />"
                 });
                 $('#events-day-results').html(result);
         }
      }
}; //end function
/* end sidebar.event.blade.php */

/* pages.hotels-results.blade.php */
$(document).ready(function(){
  if(window.location.pathname == '/hotels/search' || window.location.pathname == '/hotels'){
    console.log('hotels');
    $('ul.pagination > li > a').on('click', function(e){
                e.preventDefault();
                $.ajax({
                    url: $(this).attr('href'),
                    method: 'GET',
                    success: function(response){
                        $('#results-wrapper').html(response);
                    }
                });
        });

        $('.hotel-rating').each(function(){
            var rate = $(this).attr('data-value');
            var id = $(this).attr('id');
            console.log(rate);
            $('#' + id).jRate({
                onSet: function(rate){
                },
                startColor: '#f08a24',
                endColor: '#f08a24',
                rating: $(this).attr('data-value'),
                readOnly: true,
                precision: 0.5,
                height: 15,
            });
        });
    }
  });       
/* end pages.hotels-results.blade.php */

/* pages.attraction.blade.php */
$(document).ready(function(){
    $(".btn-nav").click(function(e){
      e.preventDefault();
      var let = $(this).attr('data-value');
      var url = $("input[name=url]").val();
      $.ajax({
        url: url+"/attractions/sort/" + let,
        beforeSend: function (xhr) {
                var token = $('meta[name="csrf_token"]').attr('content');
                if (token) {
                      return xhr.setRequestHeader('X-CSRF-TOKEN', token);
                }
            },
        success: function(data){
          $('.list-result').html(data);
          $(".select-location").val('0');
        }
      });
    });
    $(".submit").click(function(e){
      e.preventDefault();
      var loc = $("[name='location']").val();
      var url = $("input[name=url]").val();
      $.ajax({
        url: url+"/attractions/sort/" + loc,
        beforeSend: function (xhr) {
                var token = $('meta[name="csrf_token"]').attr('content');
                if (token) {
                      return xhr.setRequestHeader('X-CSRF-TOKEN', token);
                }
            },
        success: function(data){

          $('.list-result').html(data);
          if( $.trim( $('.list-result').html() ).length == 0 ) {
            $('.list-result').html('<div class="row result empty">There are currently no attractions in this location</div>');
          }
        }
      })
    });
    //$(".submit").trigger('click');

  });
/* end pages.attraction.blade.php */

/* pages.event.blade.php */
$.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });

  $(document).ready(function(){
    var month = $("[name='month']").val();
    var curr_month = new Date().getMonth()+1;
    getLocations(month);
    if(month==curr_month){
      $('.whatshappening').html("<div class='happening-now'>What's happening in the Philippines now?</div>");
    }

    $(".submit").click(function(e){
      //alert('Hi');
      e.preventDefault();
      var url = $("input[name=url]").val();
      var month = $("[name='month']").val();
      var location = $("[name='location']").val();
      if(month==curr_month){
        $('.whatshappening').html("<div class='happening-now'>What's happening in the Philippines</div>");
      }else{
        $('.whatshappening').html('');
      }
      $.ajax({
        url: url+'/events/sort/'+month+'/'+location,
        success: function(data) {
          $('.result-page').html(data);
        }
      })
    })
  });

  function getLocations(id){
    var url = $("input[name=url]").val();
    $.ajax({
      url: url+'/events/sort/'+id,
      success: function(data){
        $(".locations").html(data);
      }
    })
  };
/* end pages.event.blade.php */

/* layout.hotel*/
window.onload = function(){
      var nowTemp = new Date();
      var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
      var checkin = $('#checkin-date').fdatepicker({
        format: 'yyyy-mm-dd',
        onRender: function (date) {
          return date.valueOf() < now.valueOf() ? 'disabled' : '';
        }
      }).on('changeDate', function (ev) {
        if (ev.date.valueOf() > checkout.date.valueOf()) {
          var newDate = new Date(ev.date)
          newDate.setDate(newDate.getDate() + 1);
          checkout.update(newDate);
        }
        checkin.hide();
        $('#checkout-date')[0].focus();
      }).data('datepicker');  
      var checkout = $('#checkout-date').fdatepicker({
        format: 'yyyy-mm-dd',
        onRender: function (date) {
          return date.valueOf() <= checkin.date.valueOf() ? 'disabled' : '';
        }
      }).on('changeDate', function (ev) {
        checkout.hide();
      }).data('datepicker');
    }
    /*end layout.hotel.blade.php*/

/* destinations.profile.blade.php */

$( document ).ready( function(){
  if(($('title').text()).search('Destinations') != -1)
      $('#hotel-city').val($('.header-text').text());
    });

/* end destinations.profile.blade.php */
